import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./scss/themes/mytheme/theme.scss"

import { PrimeReactProvider } from "primereact/api";

import "primereact/resources/themes/saga-blue/theme.css"; // theme
import "primereact/resources/primereact.min.css"; // core css
import "primeicons/primeicons.css"; // icons

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <App />
    </PrimeReactProvider>
  </React.StrictMode>
);
