import React from "react";
import { BrowserRouter, createBrowserRouter, Navigate, Route, RouterProvider, Routes } from "react-router-dom";
import Login from "./pages/Login/login";
import LayoutFormat from "./Routing/Layout";
import ProtectedRoute from "./Routing/routes/protectedRoutes";
import routes from "./Routing/routes/routes";
import { auth_routes } from "./Routing/routes/UnProtectedRoutes";
import RedirectOnLoggedIn from "./Routing/routes/RedirectOnLoggedIn";
import PageNotFound from "./pages/PageNotFound";

export default function App() {
    // return (
    //     <BrowserRouter>
    //         <MainRoute />
    //     </BrowserRouter >
    // )

    const loginRoutes = () => {
        return auth_routes.map((route) => (
            {
                path: route.path,
                element: (
                    <RedirectOnLoggedIn>
                        {route.element}
                    </RedirectOnLoggedIn>
                )
            }
        ))
    };

    const router = createBrowserRouter(
        [
            ...loginRoutes(),
            {
                path: "/",
                element: <LayoutFormat />,
                children: routes.map((route) => ({
                    ...route,
                    element: (
                        <ProtectedRoute userRoles={route?.permissions}>
                            {route.element}
                        </ProtectedRoute>
                    )
                }))
            },
        ]);

    return <RouterProvider router={router} />;
}