import * as React from "react";
import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useLocation } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import logo from "../helper/logo/Adelement-ApprovalStatus-Logo.svg";
import { UserProfile } from "./tostify/UserProfile";
import { positions } from "@mui/system";
import { isAuthorized } from "../auth/authUtil";
import { ROLE_ADMIN, ROLE_USER } from "../assets/const/roles";

const drawerWidth = 240;

export default function LeftsideBar() {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(1);
    const location = useLocation();
    // const getPageTitle = () => {
    //   switch (location.pathname) {
    //     case '/demand':
    //       return 'Approvals/Demand';
    //     case '/supply':
    //       return 'Approvals/Supply';
    //     default:
    //       return '';
    //   }
    // };
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const type = location.pathname.split("/")[1];
        let index = 1;
        switch (type) {
            case "":
                index = 1;
                setOpen(true)
                break;
            case "demand":
                index = 1;
                setOpen(true)
                break;
            case "supply":
                index = 2;
                setOpen(true)
                break;
            case "apps":
                index = 3;
                break;
            case "publishers":
                index = 4;
                break;
            case "manageUsers":
                index = 5;
                break;
            default:
                index = 1
                break;
        }
        setSelectedIndex(index);
    }, [location.pathname]);

    const handleToggle = (state) => {
        if (state === false) {
            setOpen(state);
        } else {
            setOpen(!open);
        }
    };

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const drawer = (
        <Box
            className="sidebar"
            sx={{
                minHeight: 0,
                overflow: "hidden auto",
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                paddingX: "1rem",
            }}
        >
            <Toolbar className="menu-toolbar main-content-header">
                <Box className="logo-wrapper">
                    <img src={logo} className="brand-logo" alt="logo" />
                </Box>
            </Toolbar>
            <List component="nav" style={{flexGrow: "1" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <ListItemButton
                        onClick={(event) => {
                            handleListItemClick(event, 1);
                            handleToggle(true);
                        }}
                        selected={selectedIndex === 0}
                        component={Link}
                        to="/demand"
                    >
                        <i
                            className="pi pi-check-circle"
                            style={{ marginRight: "10px", color: "#667085" }}
                        ></i>
                        <ListItemText primary="Approvals" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </div>
                <Collapse in={open} timeout="auto" unmountOnExit className="t-indent-3">
                    <List component="div" disablePadding>
                        {
                            isAuthorized([ROLE_ADMIN, ROLE_USER]) ?
                                <ListItem disablePadding>
                                    <ListItemButton
                                        component={Link}
                                        to="/demand"
                                        selected={selectedIndex === 1}
                                        onClick={(event) => handleListItemClick(event, 1)}
                                    >
                                        <ListItemText primary="Demand" />
                                    </ListItemButton>
                                </ListItem>
                                : <div></div>
                        }
                        {
                            isAuthorized([ROLE_ADMIN, ROLE_USER]) ?
                                <ListItem disablePadding>
                                    <ListItemButton
                                        component={Link}
                                        to="/supply"
                                        selected={selectedIndex === 2}
                                        onClick={(event) => handleListItemClick(event, 2)}
                                    >
                                        <ListItemText primary="Supply" />
                                    </ListItemButton>
                                </ListItem>
                                : <div></div>
                        }
                    </List>
                </Collapse>
                {
                    isAuthorized([]) ?
                        <ListItem disablePadding>
                            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                <ListItemButton
                                    component={Link}
                                    to="/apps"
                                    selected={selectedIndex === 3}
                                    onClick={(event) => {
                                        handleListItemClick(event, 3);
                                        handleToggle(false);
                                    }}
                                >
                                    <i
                                        className="pi pi-mobile"
                                        style={{ marginRight: "10px", color: "#667085" }}
                                    ></i>
                                    <ListItemText primary="Apps" />
                                </ListItemButton>
                            </div>
                        </ListItem>
                        : <div></div>
                }
                {
                    isAuthorized([]) ?
                        <ListItem disablePadding>
                            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                <ListItemButton
                                    component={Link}
                                    to="/publishers"
                                    selected={selectedIndex === 4}
                                    onClick={(event) => {
                                        handleListItemClick(event, 4);
                                        handleToggle(false);
                                    }}
                                >
                                    <i
                                        className="pi pi-desktop"
                                        style={{ marginRight: "10px", color: "#667085" }}
                                    ></i>
                                    <ListItemText primary="Publishers" />
                                </ListItemButton>
                            </div>
                        </ListItem>
                        : <div></div>
                }
                {
                    isAuthorized([ROLE_ADMIN]) ?
                        <ListItem disablePadding>
                            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                <ListItemButton
                                    component={Link}
                                    to="/manageUsers"
                                    selected={selectedIndex === 5}
                                    onClick={(event) => {
                                        handleListItemClick(event, 5);
                                        handleToggle(false);
                                    }}
                                >
                                    <i className="pi pi-user" style={{ marginRight: '10px', color: '#667085' }}></i>
                                    <ListItemText primary="Manage Users" />
                                </ListItemButton>
                            </div>
                        </ListItem>
                        : <div></div>
                }
            </List>
            <Divider/>

            {/* ................user profile............. */}
            <div>
                <UserProfile />
            </div>
        </Box>
    );

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": { width: drawerWidth, boxSizing: "border-box" },
                }}
                variant="permanent"
                anchor="left"
            >
                {drawer}
            </Drawer>
        </Box>
    );
}
