import React from 'react'
import { Avatar, Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import md5 from 'md5' // Make sure to install this package
import { getLoggedInUser, setLoggedInUser } from '../../auth/authUtil'
import Logout from '../Login/Logout'

const getGravatarUrl = (email) => {
    const hash = md5(email.trim().toLowerCase());
    return `https://www.gravatar.com/avatar/${hash}?s=200&d=identicon`;
}

export const UserProfile = ({ open }) => {
    let user = getLoggedInUser();
    const gravatarUrl = user?.email ? getGravatarUrl(user.email) : 'https://www.gravatar.com/avatar?d=identicon';
    return (
        <div>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', padding: '1rem 0', textAlign: 'left' }} className="profile-menu">
                <Link to='/manageUsers' className='profile-avatar'>
                    <Avatar
                        variant="outlined"
                        size="sm"
                        src={gravatarUrl}
                    />
                    <Box sx={{ minWidth: 0, flex: 1, display: open ? 'none' : 'block' }}>
                        <Typography level="title-sm" style={{textAlign: "left"}}>{user?.firstName}</Typography>
                        {/* <Typography level="body-xs" className='user-email'>{user?.email}</Typography> */}
                    </Box>
                </Link>
                <Link to='/login' className='logout-btn'>
                    <Logout />
                </Link>
            </Box>
        </div>
    )
}
