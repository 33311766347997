import { openDB } from 'idb';

const dbApprovals = openDB('Approvals', 2, {
  upgrade(db) {
    db.createObjectStore('DemandData');
    db.createObjectStore('SupplyData');
  },
});

const dbAppPublisher = openDB('AppPublisher', 2, {
  upgrade(db) {
    db.createObjectStore('AppData');
    db.createObjectStore('PublishersData');
  },
});


// .........SAVING THE DATA.........

export const saveToIndexedDBDemand = async (key, data) => {
  const db = await dbApprovals;
  await db.put('DemandData', data, key); 
};
export const saveToIndexedDBSupply = async (key, data) => {
  const db = await dbApprovals;
  await db.put('SupplyData', data, key); 
};
export const saveToIndexedDBApp = async (key, data) =>{
  const db = await dbAppPublisher;
  await db.put('AppData', data, key);
};
export const saveToIndexedDBPublishers = async (key, data) =>{
  const db = await dbAppPublisher;
  await db.put('PublishersData', data, key);
};

//.......LOADING THE DATA.........

export const loadFromIndexedDBDemand = async (key) => {
  const db = await dbApprovals;
  return await db.get('DemandData', key);
};

export const loadFromIndexedDBSupply = async (key) => {
  const db = await dbApprovals;
  return await db.get('SupplyData', key);
};

export const loadFromIndexedDBApp = async (key) => {
  const db = await dbAppPublisher;
  return await db.get('AppData', key);
};

export const loadFromIndexedDBPublishers = async (key) => {
  const db = await dbAppPublisher;
  return await db.get('PublishersData', key);
};
