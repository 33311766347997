function getOptions(requestType, data) {
    let options = {
        headers: {
            'Authorization': 'Bearer ' + getAuthToken(),
        },
        method: requestType,
        data: data,
    };
    if (!(data instanceof FormData)) {
        options.headers['Content-Type'] = 'application/json';
    }
    return options;
}

const isAuthorized = (route) => {
    let isAuth = false;
    let user = getLoggedInUser();
    for (let i = 0; i < user?.authorities?.length; i++) {
        if (route.includes(user?.authorities[i])) {
            isAuth = true;
        }
    }
    return isAuth;
}

const getAuthToken = () => {
    const localStorage = window.localStorage;
    const token = localStorage.getItem('ae-internal-ui');
    return token ? token : null;
}

const setAuthToken = (token) => {
    const localStorage = window.localStorage;
    localStorage.setItem('ae-internal-ui', token);
}

function getLoggedInUser() {
    const localStorage = window.localStorage;
    let user = localStorage.getItem("user");
    if (user) {
        try {
            user = JSON.parse(user);
        } catch (error) {
            console.error("Failed to parse user data from localStorage:", error);
            user = null;
        }
    } else {
        user = null;
    }
    return user;
}


export { getAuthToken, getOptions, setAuthToken, getLoggedInUser,isAuthorized };