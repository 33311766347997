import { Navigate } from "react-router-dom";
import { getLoggedInUser } from "../../auth/authUtil";

const RedirectOnLoggedIn = ({ children }) => {

    let currentUser = getLoggedInUser();
    if (currentUser && currentUser?.authorities) {
        return <Navigate to="/demand" />
    }
    return children;
}
export default RedirectOnLoggedIn;