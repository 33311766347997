import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Editing } from 'devextreme-react/data-grid';
import { DataGrid, Column, Paging, FilterRow, HeaderFilter } from 'devextreme-react/data-grid';
import { TagBox } from 'devextreme-react/tag-box';
import { getRequest, putRequest } from '../../helper/ApiHandler';
import { Modal, Box, Grid } from "@mui/material";
import Registration from './UserRegistration';
import LoadPanel from 'devextreme-react/cjs/load-panel';
import { putUser } from '../../helper/ApiHandler';
import CloseButtonIcon from '../../assets/images/icons/close.svg';

export default function Users() {
    const toast = useRef(null);
    const [userData, setUserData] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        setIsloading(true);
        try {
            const response = await getRequest('/api/admin/users');
            const modifiedUsers = response.data.map(user => ({
                ...user,
                firstName: `${user.firstName || ''} ${user.lastName || ''}`,
                status: user.activated ? 'Active' : 'Stopped',
                role: userRoleName(user),
            }));
            setUserData(modifiedUsers);
        } catch (error) {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: error.message || error });
        } finally {
            setIsloading(false);
        }
    };

    const userRoleName = (userData) => {
        const roles = userData.authorities || [];
        return roles.map(item => item.replace(/^ROLE_/, ''));
    };

    const buildPayload = (data) => {
        const fullName = data.firstName || '';
        const [firstName, ...lastNameParts] = fullName.split(' ');
        const lastName = lastNameParts.join(' ');

        return {
            id: data.id,
            login: data.login || "defaultLogin",
            firstName: firstName,
            lastName: lastName,
            email: data.email,
            imageUrl: data.imageUrl || "",
            activated: data.status === "Active",
            langKey: data.langKey || "en",
            createdBy: data.createdBy || "system",
            createdDate: data.createdDate || new Date().toISOString(),
            lastModifiedBy: "currentUser",
            lastModifiedDate: new Date().toISOString(),
            authorities: data.role.map(item => `ROLE_${item}`) || [],
        };
    };

    const handleUpdateUser = async (data, navigate) => {
        const payload = buildPayload(data);

        try {
            const result = await putUser(`/api/admin/users`, payload, navigate);
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'User updated successfully!' });
            await getUser();
        } catch (error) {
            console.error("Error updating user:", error);
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to update user.' });
        }
    };

    const TagBoxCell = (cellData) => {
        return <div>
            <TagBox
                items={['USER', 'ADMIN']}
                defaultValue={cellData.data.value}
                onValueChanged={(e) => {
                    if (e.value.length === 0) {
                        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Please select a Role.' });
                        return
                    }
                    cellData.data.setValue(e.value);

                }}
                showSelectionControls={true}
                placeholder="Select roles"
                multiline={true}
            />
        </div>
    }



    return (
        <div>
            <Toast ref={toast} position="bottom-right" />
            <Toolbar className="main-content-header">
                <Typography variant="h6" noWrap component="div">
                    <h3>Manage Users</h3>
                </Typography>
                <Button
                    className='button-style-1'
                    // variant="contained" 
                    onClick={handleOpen}>
                    Register
                </Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            // width: 400,
                            // bgcolor: 'background.paper',
                            // border: '2px solid #000',
                            // boxShadow: 24,
                            // p: 4,
                        }}
                    >
                        {/* <Registration handleClose={handleClose} /> */}
                        <Button className="registration-modal" />
                        <Registration 
                        handleClose={handleClose}
                        getUser={getUser}
                         />
                    </Box>
                </Modal>
            </Toolbar>
            <div style={{ height: "100%", width: "100%", position: "relative" }}
                id="dataGridContainer">
                <LoadPanel
                    visible={isLoading}
                    container="#dataGridContainer"
                    shading={false}
                    position={{ of: "#dataGridContainer" }}
                />
                <DataGrid
                    dataSource={userData}
                    keyExpr="id"
                    showBorders={true}
                    allowEditing={true}
                    onRowUpdated={(e) => handleUpdateUser(e.data)}
                >
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <Paging defaultPageSize={10} />
                    <Editing
                        mode="row"
                        allowUpdating={true}
                        startEditAction="click"
                    />
                    <Column dataField="firstName" caption="Name" />
                    <Column dataField="email" caption="Email" allowEditing={false} />
                    <Column
                        dataField="role"
                        caption="Role"
                        allowEditing={true}
                        editCellComponent={TagBoxCell}
                    />
                    <Column
                        dataField="status"
                        caption="Status"
                        allowEditing={true}
                        lookup={{
                            dataSource: ["Active", "Stopped"],
                            valueExpr: "this",
                            displayExpr: "this",
                        }}
                    />
                </DataGrid>
            </div>
        </div>
    );
}


