import { IconButton } from '@mui/material'
import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

export default function Logout() {

    const handleLogout = () => {
        localStorage.removeItem('ae-internal-ui');
        localStorage.removeItem('user');
        return <Navigate to="/login" />
    }

    return (
        <div>
            <IconButton size="sm" variant="plain" color="neutral" onClick={handleLogout}>
                <PowerSettingsNewIcon  />
            </IconButton>
        </div>
    )
}
