import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import '../../scss/themes/mytheme/registration.css';
import { postRequest } from '../../helper/ApiHandler';
import { Toast } from 'primereact/toast';
import LoadPanel from 'devextreme-react/cjs/load-panel';
import { InputText } from 'primereact/inputtext';
import UserNameIcon from '../../assets/images/icons/username.svg';
import PasswordIcon from '../../assets/images/icons/password.svg';
import EmailIcon from '../../assets/images/icons/email.svg';
import CloseButtonIcon from '../../assets/images/icons/close.svg';
import { generatePassword } from '../../helper/password/generate';

const getPasswordStrength = (password) => {
    const lengthCriteria = password.length >= 8;
    const hasNumbers = /[0-9]/.test(password);
    const hasLetters = /[a-zA-Z]/.test(password);
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (lengthCriteria && hasNumbers && hasLetters && hasSpecialChars) return 'Strong';
    if (lengthCriteria && hasNumbers && hasLetters) return 'Medium';
    if (lengthCriteria) return 'Weak';
    return 'Too Short';
};

const Registration = (props) => {
    const [login, setLogin] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState(generatePassword(10));
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (event) => {
        event.preventDefault();
        const trimmedUsername = login.trim();
        if (trimmedUsername === '') {
            alert("Username cannot be empty or just spaces.");
            return;
        }
        if (firstName === '') {
            alert("First name cannot be empty")
            return;
        }
        if (firstName === '') {
            alert("Last name cannot be empty")
            return;
        }

        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        const user = {
            login: trimmedUsername,
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            langKey: "en"
        };

        console.log(user);
        try {
            setLoading(true)
            const response = await postRequest("/api/register", user);
            if (toast.current) {
                toast.current.show({ severity: "success", summary: 'Success', detail: 'User registration successful', });
                props.handleClose();
                props.getUser();
            }
        } catch (error) {
            console.error("Error during registration", error);
            if (toast.current) {
                toast.current.show({ severity: "error", summary: 'Error', detail: error, });
            }
        }
        setLoading(false)
    };

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setPasswordStrength(getPasswordStrength(newPassword));
    };

    return (
        <>
            <Toast ref={toast} position="bottom-right" />
            <div style={{ height: "100%", width: "100%", position: "relative" }}
                id="dataGridContainer">
                <LoadPanel
                    visible={loading}
                    container="#dataGridContainer"
                    shading={false}
                    position={{ of: "#dataGridContainer" }}
                />
                <div className="registration-container">
                    <form onSubmit={handleRegister}>
                        {/* <div className="input-group">
                                <label>Username</label>
                                <input
                                    type="text"
                                    value={login}
                                    onChange={(e) => setLogin(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-group">
                                <label>First Name</label>
                                <input
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-group">
                                <label>Last Name</label>
                                <input
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div> 
                            <div className="input-group">
                                <label>Password</label>
                                <div className="password-input-wrapper">
                                    <input
                                        type={isPasswordVisible ? 'text' : 'password'}
                                        value={password}
                                        onChange={handlePasswordChange}
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="password-toggle-btn"
                                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                    >
                                        {isPasswordVisible ? '🙈' : '👁️'}
                                    </button>
                                </div>
                                <div className="password-strength">
                                    Password Strength: {passwordStrength}
                                </div>
                            </div>
                            <div className="input-group">
                                <label>Confirm Password</label>
                                <div className="password-input-wrapper">
                                    <input
                                        type={isConfirmPasswordVisible ? 'text' : 'password'}
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="password-toggle-btn"
                                        onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                                    >
                                        {isConfirmPasswordVisible ? '🙈' : '👁️'}
                                    </button>
                                </div>
                            </div> */}
                        {/* <label>Username</label> */}
                        <h2 id="modal-title">Register</h2>
                        <div className='modal-title button-style-close'
                            variant="outlined"
                            onClick={props.handleClose}
                            sx={{ mt: 2 }}
                        > <img src={CloseButtonIcon} alt="close-button" />
                        </div>

                        <div className="p-inputgroup flex-1">
                            <span className="p-inputgroup-addon">
                                <img src={UserNameIcon} alt="user-icon" />
                            </span>
                            <InputText placeholder="Username"
                                type="text"
                                value={login}
                                onChange={(e) => setLogin(e.target.value)} />
                        </div>
                        {/* <label>First Name</label> */}
                        <div className="p-inputgroup no-addon flex-1">
                            {/* <span className="p-inputgroup-addon">
                        <img src={UserNameIcon} alt="user-icon"/>
                    </span> */}
                            <InputText placeholder="First Name"
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        {/* <label>Last Name</label> */}
                        <div className="p-inputgroup no-addon flex-1">
                            {/* <span className="p-inputgroup-addon">
                        <img src={UserNameIcon} alt="user-icon"/>
                    </span> */}
                            <InputText placeholder="Last Name"
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)} />
                        </div>
                        <div className="p-inputgroup flex-1">
                            <span className="p-inputgroup-addon">
                                <img src={EmailIcon} alt="email-icon" />
                            </span>
                            <InputText placeholder="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        {/* <label>Password</label> */}
                        <div className="p-inputgroup flex-1">
                            <span className="p-inputgroup-addon">
                                <img src={PasswordIcon} alt="user-icon" />
                            </span>
                            {/* <InputText placeholder="Password"
                                type={isPasswordVisible ? 'text' : 'password'}
                                value={password}
                                onChange={handlePasswordChange}
                                required
                            /> */}
                             <input
                            type={isPasswordVisible ? 'text' : 'password'}
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                        <button
                            type="button"
                            className="password-toggle-btn"
                            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        >
                            {isPasswordVisible ? '🙈' : '👁️'}
                        </button>
                        </div>
                        {/* <label>Confirm Password</label> */}
                        <div className="p-inputgroup flex-1">
                            <span className="p-inputgroup-addon">
                                <img src={PasswordIcon} alt="user-icon" />
                            </span>
                            {/* <InputText placeholder="Confirm Password"
                                type={isConfirmPasswordVisible ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            /> */}
                            <input
                                type={isConfirmPasswordVisible ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                className="password-toggle-btn"
                                onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                            >
                                {isConfirmPasswordVisible ? '🙈' : '👁️'}
                            </button>
                        </div>
                        <button type="submit" className="button-style-1" >Register</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Registration;

