import React from "react";
import DevExtreme from "../Grids/devExtreme";


const supplyEndpoints = {
  fetch: '/api/approvals/supply-app-approval-status/export',
};
const updateEndpoint = {
  fetch: '/api/approvals/supply-app-approval-status'
}

function ManageSupply() {
  return (
    <>
    <DevExtreme
    endpoints={supplyEndpoints}
    updateEndpoint={updateEndpoint}
    address="Supply"
    />
    </>
  );
}

export default ManageSupply;
