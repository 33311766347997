import React, { useEffect, useState, useRef } from "react";
import Papa from "papaparse";
import DataGrid, { Column, Paging, FilterRow, Editing, Lookup, Grouping, GroupPanel, Export, Summary, TotalItem, StateStoring, } from "devextreme-react/data-grid";
import { LoadPanel } from 'devextreme-react/load-panel';
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import "devextreme/dist/css/dx.fluent.blue.light.css";
import PivotGrid, { FieldChooser } from "devextreme-react/cjs/pivot-grid";
import { Toast } from "primereact/toast";
import { Button, CircularProgress } from "@mui/material";
import {BounceLoader} from "react-spinners"
import { putRequest } from "../../helper/ApiHandler";
import {
    loadFromIndexedDBSupply,
    saveToIndexedDBSupply,
    saveToIndexedDBDemand,
    loadFromIndexedDBDemand,
} from "../../helper/indexedDB";
import { csvParse } from "../../helper/ApiHandler";
import { useLocation, useNavigate } from "react-router";

export default function DevExtreme({
    endpoints,
    address,
    updateEndpoint,
}) {
    const [gridData, setGridData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState(null);
    const [pivot, setPivot] = useState(false);
    const pivotGridRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const toast = useRef(null);
    const [updateState,setUpdateState]=useState(true);
    const columnTypeMapping = {
        "App bundle": "string",
        code: "string",
        "Total Revenue": "string",
        Rank: "string",
        "Date Updated": "date",
    };

    const fields = [
        { dataField: "Code", caption: "Code", dataType: "string" },
        {
            dataField: "App Bundle",
            caption: "App Bundle",
            dataType: "string",
        },
        {
            dataField: "Total Revenue",
            caption: "Total Revenue",
            dataType: "number",
            summaryType: "sum",
        },
        { dataField: "Status", caption: "Status", dataType: "string" },
        {
            dataField: "Date Updated",
            caption: "Date Updated",
            dataType: "date",
        },
        {
            dataField: "Line Inserted",
            caption: "Line Inserted",
            dataType: "date",
        },
        { dataField: "ID", caption: "ID", dataType: "number" },
        { dataField: "id", caption: "id", dataType: "number", visible: false },
        { dataField: "user_id", caption: "user_id", dataType: "number", visible: false },
        { dataField: "Rank", caption: "Rank", dataType: "number" },
    ];

    useEffect(() => {
        fetchCsvData(false);
    }, []);

    //..........IndexDB Method...................
    const fetchCsvData = async (skipCache) => {
        try {
            if (!skipCache) {
                let cachedData;
                if (address === "Demand") {
                    cachedData = await loadFromIndexedDBDemand("csvData");
                }
                else if (address === "Supply") {
                    cachedData = await loadFromIndexedDBSupply("csvData");
                }
                console.log("cachedData", cachedData);
                if (cachedData) {
                    const pivotDataSource = new PivotGridDataSource({
                        fields: cachedData.columns,
                        store: cachedData.data,
                    });
                    setDataSource(pivotDataSource);
                    setGridData(cachedData.data);
                    setColumns(cachedData.columns);
                    setLoading(false);
                }
            }
            if (toast.current) {
                toast.current.show({ severity: "info", summary: 'Info', detail: 'Please wait while your data is updating', setTimeout: 4000 });
            }
            const response = await csvParse(endpoints.fetch, navigate);
            if (response.status === 200) {
                const csvText = response.data;
                setDataToIndexDB(csvText);
                setUpdateState(false);

            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };


    const setDataToIndexDB = (csvText) => {
        Papa.parse(csvText, {
            header: true,
            skipEmptyLines: true,
            complete: async (results) => {

                const parsedData = results.data.map((item, index) => {

                    return {
                        ...item,
                        //  Rank: item.Rank != "null" ? parseInt(item.Rank) : null,
                        id: index + 1,
                        ID: (parseInt(item.ID)),
                    };
                });

                console.log("parsedData", parsedData);

                const processedData = results.data.map((row) => (

                    {
                        ...row,

                        "Total Revenue":
                            row["Total Revenue"] !== null &&
                                row["Total Revenue"] !== "" &&
                                !isNaN(row["Total Revenue"])
                                ? parseFloat(row["Total Revenue"])
                                : null,
                        Rank: parseFloat(row["Rank"]) || 0,
                        "Line Inserted": row["Line"] ? new Date(row["Line Inserted"]) : null,
                    }));

                // Generate dynamic columns
                const headers = Object.keys(parsedData[0]);
                const dynamicColumns = headers.map((header) => ({
                    dataField: header,
                    caption: header.replace(/_/g, " "),
                    dataType: columnTypeMapping[header] || "string",
                }));


                const pivotDataSource = new PivotGridDataSource({
                    fields,
                    store: processedData,
                });

                if (address === "Demand") {
                    await saveToIndexedDBDemand("csvData", {
                        data: parsedData,
                        columns: dynamicColumns,
                    });
                }
                else if (address === "Supply") {
                    await saveToIndexedDBSupply("csvData", {
                        data: parsedData,
                        columns: dynamicColumns,
                    });
                }


                setDataSource(pivotDataSource);
                setColumns(dynamicColumns);
                setGridData(parsedData);
                setLoading(false);
                console.log(".....After state update.......");

            },
        });
    }

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "DataGrid.xlsx"
                );
            });
        });
    };

    const handleStatusUpdate = async (ID, status,) => {
        try {
            setUpdateState(true)
            if (toast.current) {
                toast.current.show({ severity: "info", summary: 'Info', detail: 'Please wait while status is updating', });
            }
            const response = await putRequest(
                updateEndpoint.fetch,
                ID,
                status
            );
            if (toast.current) {
                toast.current.show({ severity: "success", summary: 'Success', detail: 'Status updated Successfully', });
            }

        } catch (error) {
            if (toast.current) {
                toast.current.show({ severity: "error", summary: 'Error', detail: error, });
            }
        }
        fetchCsvData(true);
    };

    const handleRowUpdate = async (e) => {
        const { ID, Status } = e.data;
        if (ID && Status) {
            await handleStatusUpdate(ID, Status);
        }
    };

    const customizeToolbar = (e) => {
        const fieldChooserButton = e.toolbarOptions.items.find(
            (item) => item.name === "fieldChooser"
        );
        if (fieldChooserButton) {
            fieldChooserButton.text = "Select Fields";
            fieldChooserButton.icon = "";
        }
    };

    const togglePivot = () => {
        setPivot(!pivot);
    };

    const getStatusClass = (status) => {
        switch (status) {
          case "APPROVED":
            return "status-approved";
          case "REJECTED":
            return "status-rejected";
          case "LINE_PRESENT":
            return "status-line-present";
          case "APPROVAL_REQUESTED":
            return "status-approval-requested";
          default:
            return "";
        }
      };
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <h3>Approvals/{address}</h3>
                <div style={{ marginLeft: 'auto' }}>
                    <button
                        style={{
                            marginRight: '10px',
                        }}
                        className="button-style-1"
                        onClick={togglePivot}
                    >
                        Pivot
                    </button>
                    <button
                        style={{}}
                        className="button-style-1"
                        onClick={() => {
                            navigate('/import', { state: { approvalType: address }});
                        }}
                    >
                        Import
                    </button>
                </div>
            </div>

            {pivot ? (
                <div style={{ height: "600px", width: "1600px", marginTop: "60px" }}>
                    {loading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    ) : (
                        <PivotGrid
                            ref={pivotGridRef}
                            dataSource={dataSource}
                            allowSorting={true}
                            allowFiltering={true}
                            allowExpandAll={true}
                            showBorders={true}
                            rowHeaderLayout="tree"
                            customizeToolbar={customizeToolbar}
                        >
                            <FieldChooser
                                enabled={true}
                                height={700}
                                allowSearch={true}
                                applyChangesMode={"onDemand"}
                            />
                        </PivotGrid>
                    )}
                </div>
            ) : (
                <div style={{ height: "600px", width: "100%" }}>
                    <Toast ref={toast} position="bottom-right" />
                    <div style={{ height: "100%", width: "100%", position: "relative" }}
                        id="dataGridContainer">
                        <LoadPanel
                            visible={loading}
                            container="#dataGridContainer"
                            shading={false}
                            position={{ of: "#dataGridContainer" }}
                        />
                       { updateState ?<BounceLoader
                            color={"#f70a0a"}
                            size={15}
                        />
                        :null
                       }
                        <DataGrid
                            dataSource={gridData}
                            showBorders={true}
                            keyExpr="id"
                            loadPanel={false}
                            rowAlternationEnabled={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            showRowLines={true}
                            onExporting={onExporting}
                            onRowUpdated={handleRowUpdate}
                            onEditorPreparing={(e) => {
                                if (e.dataField === "Status" && e.parentType === "dataRow") {
                                    e.editorOptions.onValueChanged = async (args) => {
                                        e.setValue(args.value);
                                        e.component.closeEditCell();
                                    };
                                }
                            }}
                            onCellPrepared={(e) => {
                                if (e.column.dataField === "Status" && e.rowType === "data") {
                                    const statusClass = getStatusClass(e.value);
                                    if (statusClass) {
                                        e.cellElement.classList.add(statusClass);
                                    }
                                }
                            }}
                        >
                            <StateStoring
                                enabled={true}
                                type="localStorage"
                                storageKey="myDataGridStorage"
                            />
                            <Summary>
                                <TotalItem column="ID" summaryType="count" />
                                <TotalItem
                                    column="Total Revenue"
                                    summaryType="sum"
                                    valueFormat="currency"
                                />
                            </Summary>
                                <GroupPanel visible={true} />
                                <Paging defaultPageSize={10} />
                                <Grouping autoExpandAll={false} />
                                <Editing mode="cell" allowUpdating={true} startEditAction="click" />
                                <Paging defaultPageSize={500} />
                                <FilterRow visible={true} applyFilter={true} />
                                <Export enabled={true} allowExportSelectedData={false} />
                            {columns.map((col) => (
                                <Column
                                    key={col.dataField}
                                    dataField={col.dataField}
                                    caption={col.caption}
                                    dataType={col.dataType}
                                    visible={col.dataField !== "id" && col.dataField !== "User_id" }
                                    allowEditing={col.dataField === "Status"}
                                    sortingMethod={
                                        col.dataField === "Rank" || col.dataField === "ID" || col.dataField === "Total Revenue" ?
                                            ((a, b) => {
                                                if (a === "null") return 1;
                                                if (b === "null") return -1;
                                                return a - b;
                                            }) : null
                                    }
                                    lookup={
                                        col.dataField === "Status"
                                            ? {
                                                dataSource: [
                                                    "APPROVED",
                                                    "REJECTED",
                                                    "LINE_PRESENT",
                                                    "APPROVAL_REQUESTED",
                                                ],
                                                valueExpr: "this",
                                                displayExpr: "this",
                                            }
                                            : undefined
                                    }
                                />
                            ))}
                        </DataGrid>
                    </div>
                </div>
            )}
        </>
    );
}
