import React, { useState } from "react";


function VastSettingsForm() {
    
    return (
        <div>
            <h3>Vast Settings(TODO) </h3>
            
        </div>
    );
}

export default VastSettingsForm;
