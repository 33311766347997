import React, { useState } from "react";
import DevExtreme from "../Grids/devExtreme";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ManageDemand() {
    const navigate = useNavigate();
    const [demandDetails, setDemandDetails] = useState([])

    const demandEndpoints = {
      fetch: '/api/approvals/demand-app-approval-status/export',
    }

    const updateEndpoint = {    
      fetch: '/api/approvals/demand-app-approval-status'
    }
  return (
    <>
    <DevExtreme
    endpoints={demandEndpoints}
    updateEndpoint={updateEndpoint}
    address="Demand"
    />
    </>
  );
}

export default ManageDemand;
