import React from "react";
import { Outlet } from "react-router-dom";
import LeftsideBar from "../pages/LeftsideBar";
import { Box } from "@mui/material";

export default function LayoutFormat() {
    const drawerWidth = 270;
    return (
        <Box sx={{ display: 'flex' }}>
            <LeftsideBar />
            <Box component="main"
            sx={{
                flexGrow: 1, p: 3,
                width: { xs: `calc(100% - ${drawerWidth}px)`, sm: `calc(100% - ${drawerWidth}px)`, md: `calc(100% - ${drawerWidth}px)` }
            }}>
                <Outlet />
            </Box>
        </Box>
    )
}