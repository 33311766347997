import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import '../../scss/themes/mytheme/login.css'
import { setAuthToken } from '../../auth/authUtil';
import axios from 'axios';
import logo from "../../helper/logo/Adelement-ApprovalStatus-Logo.svg";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { getAccount } from '../../helper/ApiHandler';
import LoadPanel from 'devextreme-react/cjs/load-panel';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import UserNameIcon from '../../assets/images/icons/username.svg';
import PasswordIcon from '../../assets/images/icons/password.svg';
import handleErrors from '../../assets/apiError/ErrorHandler';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const toast = useRef(null)
    const navigate = useNavigate();
    const user = {
        "username": username,
        "password": password,
        "rememberMe": true
    };

    const handleLogin = async (event) => {
        event.preventDefault()
        const baseUrl = process.env.REACT_APP_API_ENDPOINT;
        setLoading(true)
        axios.post(baseUrl + "/api/authenticate", user)
            .then(res => {
                setAuthToken(res.data.id_token);
                getUser();
            })
            .catch(err => {
                let errMsg = handleErrors(err.response, navigate);
                if(toast.current){
                    toast.current.show({ severity: "error", summary: 'Error', detail: errMsg});
                }
            });
            setLoading(false);
    };

    const getUser = async () => {
        try {
            const user = await getAccount(`/api/account`);
            localStorage.setItem('user', JSON.stringify(user));
            navigate("/");
        } catch (error) {
            //toast.current.show({ severity: "error", summary: 'Error', detail: error });
        }
    }

    return (
        <>
        <Toast ref={toast} position="bottom-right" />
        <div className="login-container">
            <LoadPanel
                visible={loading}
            ></LoadPanel>
            <div>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} className="logo-wrapper">
                    <img src={logo} className="brand-logo" alt="logo" />
                </Box>
            </div>
            <form onSubmit={handleLogin}>
                {/* <div className="input-group">
                    <label>User name</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div> 
                <div className="input-group">
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>*/} 
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        <img src={UserNameIcon} alt="user-icon"/>
                    </span>
                    <InputText placeholder="Username"
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)} />
                </div> 
                <div className="p-inputgroup flex-1"> 
                    <span className="p-inputgroup-addon">
                        <img src={PasswordIcon} alt="user-icon"/>
                    </span>
                    <InputText placeholder="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)} />
                </div>
                <button type="submit" className="login-btn">Login</button>
            </form>
            {/* <Link to="/register" className='forgot-password-button'>Forgot your password?</Link>
            <div className='register-now'>Not a member ?<Link to="/register" className='register-now-button'> Signup Now</Link></div> */}
        </div>
        </>
    );
};

export default Login;
