import React, { useEffect, useRef, useState } from "react";
import Spreadsheet from "react-spreadsheet";
import Papa from "papaparse";
import "./BulkImport.css";
import { Toast } from "primereact/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { postRequest } from "../../helper/ApiHandler";
import { Button } from "primereact/button";
import LoadPanel from "devextreme-react/cjs/load-panel";
import { getLoggedInUser } from "../../auth/authUtil";


const BulkImport = ({ address }) => {
    const [data, setData] = useState([
        [{ value: "" }, { value: "" }, { value: "" }],
    ]);
    const location = useLocation();
    const [selectedOption, setSelectedOption] = useState(location?.state?.approvalType ?? "");
    const [history, setHistory] = useState([data]);
    const [future, setFuture] = useState([]);
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [history]);

    useEffect(() => {
        setLoading(false)
    }, [data]);


    const handleKeyDown = (event) => {
        if (event.ctrlKey && event.key === 'z') {
            undo();
        }
        if (event.ctrlKey && event.key === 'y') {
            redo();
        }
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleFileUpload = (event) => {
        setLoading(true);
        const file = event.target.files[0];

        if (!file) {
            alert("No file selected");
            return;
        }

        if (!file.name.endsWith(".csv")) {
            alert("Please upload a CSV file");
            return;
        }

        Papa.parse(file, {
            complete: (result) => {
                const csvData = result.data;

                const dataWithoutHeader = csvData.slice(1);
                const hasExcessColumns = dataWithoutHeader.some((row) => row.length > 3);

                if (hasExcessColumns) {
                    if (toast.current) {
                        toast.current.show({ severity: "error", summary: 'Error', detail: "File should contain only 3 columns [Code , App_Bundle , Status]", setTimeout: 5000, position: 'bottom-right' });
                        return;
                    }
                }

                const formattedData = dataWithoutHeader.map((row) =>
                    row.map((cell) => ({ value: cell }))
                );

                setData(formattedData);
                setHistory([formattedData]);
                setFuture([]);
            },
            header: false,
            skipEmptyLines: true,
            blankrows: false,
        });
    };


    const addRow = () => {
        setData((prevData) => {
            const newRow = prevData[0].map(() => ({ value: "" }));
            const newData = [...prevData, newRow];
            setHistory([...history, newData]);
            setFuture([]);
            return newData;
        });
    };


    const undo = () => {
        if (history.length > 1) {
            const newHistory = [...history];
            const currentData = newHistory.pop();
            const newFuture = [currentData, ...future];
            setData(newHistory[newHistory.length - 1]);
            setHistory(newHistory);
            setFuture(newFuture);
        }
    };

    const redo = () => {
        if (future.length > 0) {
            const newFuture = [...future];
            const nextData = newFuture.shift();
            const newHistory = [...history, nextData];
            setData(nextData);
            setHistory(newHistory);
            setFuture(newFuture);
        }
    };

    const handleSpreadsheetChange = (newData) => {
        if (JSON.stringify(newData) !== JSON.stringify(data)) {
            setData(newData);
            setHistory([...history, newData]);
            setFuture([]);
        }
    };

    const handleImportDemand = async () => {
        if (selectedOption === "") {
            if (toast.current) {
                toast.current.show({ severity: "error", summary: 'Error', detail: "Please select an Import Type", position: 'bottom-right' });
            }
            return;
        }

        if (selectedOption === "Demand") {
            const nonEmptyRows = data.filter((row) =>
                row.some((cell) => cell?.value && cell.value.trim() !== "")
            );


            const rowsWithMissingValues = nonEmptyRows.filter((row, index) => {
                const isMissingAnyField =
                    !row[0]?.value || !row[1]?.value || !row[2]?.value;

                if (isMissingAnyField) {

                    if (toast.current) {
                        toast.current.show({
                            severity: "error", summary: "Error", detail: `Row ${index + 1} is incomplete. Ensure all fields are filled.`, position: "bottom-right",
                        });
                    }
                    return true;
                }
                return false;
            });

            if (rowsWithMissingValues.length > 0) {
                return;
            }

            const payload = nonEmptyRows.map((row, index) => {
                const status = row[2]?.value?.toUpperCase() || null;
                const validStatusValues = ["APPROVED", "REJECTED", "LINE_PRESENT", "APPROVAL_REQUESTED"];

                if (status && !validStatusValues.includes(status)) {
                    if (toast.current) {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: `Invalid status value in Row ${index + 1}: ${row[2]?.value || "undefined"}`,
                            position: "bottom-right",
                        });
                        return
                    }
                } else {
                    return {
                        demandPartnerId: row[0]?.value || null,
                        appBundle: row[1]?.value || null,
                        status: status,
                        user_id: getLoggedInUser()?.id
                    };
                }
            });

            try {
                setLoading(true);
                const response = await postRequest('/api/approvals/demand-app-approval-status/bulk-update', payload);
                if (toast.current) {
                    toast.current.show({ severity: "success", summary: 'Success', detail: 'Imported Successfully', position: 'bottom-right' });
                    setData([
                        [{ value: "" }, { value: "" }, { value: "" }],
                    ]);
                }
            } catch (error) {
                console.error('Error sending data:', error);
                if (toast.current) {
                    toast.current.show({ severity: "error", summary: 'error', detail: error, position: 'bottom-right' });
                }
            }
            setLoading(false)
        }

        if (selectedOption === "Supply") {
            const nonEmptyRows = data.filter((row) =>
                row.some((cell) => cell?.value && cell.value.trim() !== "")
            );

            const rowsWithMissingValues = nonEmptyRows.filter((row, index) => {
                const isMissingAnyField =
                    !row[0]?.value || !row[1]?.value || !row[2]?.value;

                if (isMissingAnyField) {

                    if (toast.current) {
                        toast.current.show({
                            severity: "error", summary: "Error", detail: `Row ${index + 1} is incomplete. Ensure all fields are filled.`, position: "bottom-right",
                        });
                    }
                    return true;
                }
                return false;
            });

            if (rowsWithMissingValues.length > 0) {
                return;
            }

            const payload = nonEmptyRows.map((row, index) => {
                const status = row[2]?.value?.toUpperCase() || null;
                const validStatusValues = ["APPROVED", "REJECTED", "LINE_PRESENT", "APPROVAL_REQUESTED"];

                if (status && !validStatusValues.includes(status)) {
                    if (toast.current) {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: `Invalid status value in Row ${index + 1}: ${row[2]?.value || "undefined"}`,
                            position: "bottom-right",
                        });
                        return
                    }
                } else {
                    return {
                        supplyPartnerId: row[0]?.value,
                        appBundle: row[1]?.value,
                        status: status,
                        user_id: getLoggedInUser()?.id
                    };
                }
            });

            try {
                setLoading(true);
                const response = await postRequest('/api/approvals/supply-app-approval-status/bulk-update', payload);
                if (toast.current) {
                    toast.current.show({ severity: "success", summary: 'Success', detail: 'Imported Successfully', position: 'bottom-right' });
                }
                setData([
                    [{ value: "" }, { value: "" }, { value: "" }],
                ]);
            } catch (error) {
                console.error('Error sending data:', error);
                if (toast.current) {
                    toast.current.show({ severity: "error", summary: 'error', detail: error, position: 'bottom-right' });
                }
            }
            setLoading(false)
        }


    }
    let type = location?.state?.approvalType ?? "demand"
    return (
        <div>
            <h3>Import/{selectedOption}</h3>
            <div style={{ height: "800px", width: "100%", position: "relative" }}
                id="dataGridContainer">
                <LoadPanel
                    visible={loading}
                    container="#dataGridContainer"
                    shading={false}
                    position={{ of: "#dataGridContainer" }}
                />
                <Toast ref={toast} position="bottom-right" />
                <div className="bulk-import-header">
                    <div className="bulk-import-actions">
                        <Button
                            className="button-style-2"
                            onClick={() => {
                                navigate('/' + type);
                            }}
                            icon="pi pi-times"
                        ></Button>
                        <Button
                            className="button-style-2"
                            onClick={handleImportDemand}
                            label="Save"
                            icon="pi pi-save"
                        ></Button>
                        <Button
                            className="button-style-2"
                            onClick={addRow}
                            icon="pi pi-plus"
                            label="Add Row"></Button>
                        <Button
                            className="button-style-2"
                            onClick={undo}
                            icon='pi pi-undo'
                        ></Button>
                        <Button
                            className="button-style-2"
                            onClick={redo}
                            icon='pi pi-refresh'
                        ></Button>
                        <div className="select-container">
                            <select
                                id="options"
                                value={selectedOption}
                                onChange={handleSelectChange}
                                className="button-style-2"
                            >
                                <option value="" >Select Import</option>
                                <option value="Demand">Import for Demand</option>
                                <option value="Supply">Import for Supply</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <input
                            type="file"
                            accept=".csv"
                            onChange={handleFileUpload}
                            className="file-input"
                        />
                    </div>
                </div>
                <div className="divider"></div>
                <div className="spreadsheet-container"  >
                    <Spreadsheet
                        className="spreadsheet"
                        data={data}
                        onChange={handleSpreadsheetChange}
                        columnLabels={[`${selectedOption} Code`, "App Bundle", "Status"]}
                    />
                </div>
            </div>
        </div>
    );
};

export default BulkImport;
