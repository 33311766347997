

const PathConstants = {
    manageDemand: "/demand",
    manageSupply: "/supply",
    manageApp: "/apps",
    devExtreme: "/devExtreme",
    managePublisher: "/publishers",
    import:"/import",
    register:"/register",
    manageUsers:"/manageUsers",
    vastSettingsForm: "/vastSettings"
}

export default PathConstants
