import React from "react";
import PathConstants from "./pathConstant";
import ManageDemand from "../../pages/demand/ManageDemand";
import ManageSupply from "../../pages/supply/ManageSupply";
import ManageApps from "../../pages/apps/manageApps";
import ManagePublishers from "../../pages/publishers/publishers";
import Login from "../../pages/Login/login";
import UserRegistration from "../../pages/Login/UserRegistration"
import BulkImport from "../../pages/import/BulkImport";
import DevExtreme from "../../pages/Grids/devExtreme";
import { Navigate } from "react-router";
import VastSettingsForm from "../../pages/vastForm/VastSettingsForm";
import ManageUser from "../../pages/Login/ManageUser";
import { ROLE_ADMIN, ROLE_USER } from "../../assets/const/roles";

const routes = [
    { path: "/", element: <Navigate to="/demand" replace />, permissions: [ROLE_ADMIN, ROLE_USER] },
    { path: PathConstants.manageDemand, element: <ManageDemand />, permissions: [ROLE_ADMIN, ROLE_USER] },
    { path: PathConstants.manageSupply, element: <ManageSupply />, permissions: [ROLE_ADMIN, ROLE_USER] },
    // { path: PathConstants.manageApp, element: <ManageApps />, permissions: [ROLE_ADMIN, ROLE_USER] },
    // { path: PathConstants.managePublisher, element: <ManagePublishers />, permissions: [ROLE_ADMIN, ROLE_USER] },
    { path: PathConstants.login, element: <Login />, permissions: [ROLE_ADMIN, ROLE_USER] },
    { path: PathConstants.register, element: <UserRegistration />, permissions: [ROLE_ADMIN] },
    { path: PathConstants.import, element: <BulkImport />, permissions: [ROLE_ADMIN, ROLE_USER] },
    { path: PathConstants.manageUsers, element: <ManageUser />, permissions: [ROLE_ADMIN] },
    { path: PathConstants.vastSettingsForm, element: <VastSettingsForm/>, permissions: [ROLE_ADMIN, ROLE_USER]},
    { path: PathConstants.devExtreme, element: <DevExtreme />, permissions: [ROLE_ADMIN, ROLE_USER] }
]

export default routes;