import axios from "axios"
import { getAuthToken, getOptions } from "../auth/authUtil";
import { Navigate } from "react-router-dom";
import handleErrors from "../assets/apiError/ErrorHandler";

export const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const getRequest = async (endpoint, page, size, filterQuery = '', navigate) => {
    const queryString = filterQuery ? `${filterQuery}&page=${page}&size=${size}` : `page=${page}&size=${size}`;
    try {
      const response = await axios.get(`${baseUrl+endpoint}?${queryString}`, {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      });
      return {
        data: response.data,
        totalRecords: response.headers['x-total-count'],
      };
    } catch (error) {
      if (error.response) {
        let errMsg = handleErrors(error.response, navigate);
        throw errMsg;
    } else if (error.request) {
        const errorMessage = handleErrors(0, navigate);
        throw errorMessage;
    } else {
        throw error.message;
    }
    } 
  };

  export const getAccount = async (url, data, navigate) => {
    try {
        const response = await axios(baseUrl + url, getOptions('GET', data));
        return response.data;
    } catch (error) {
        if (error.response) {
            let errMsg = handleErrors(error.response, navigate);
            throw errMsg;
        } else if (error.request) {
            const errorMessage = handleErrors(0, navigate);
            throw errorMessage;
        } else {
            throw error.message;
        }
    }
};

  export const csvExport = async (endpoint, navigate) => {
    try {
      const response = await axios.get(`${baseUrl+endpoint}`, {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
        responseType: 'blob' 
      });
      if (response.status === 200) {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "data.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        console.error("Failed to fetch CSV:", response.statusText);
    }
    } catch (error) {
      if (error.response) {
        let errMsg = handleErrors(error.response, navigate);
        throw errMsg;
    } else if (error.request) {
        const errorMessage = handleErrors(0, navigate);
        throw errorMessage;
    } else {
        throw error.message;
    }
    }
  };

  export const putRequest = async (endpoint, id, status, navigate) => {
    try {
      const response = await axios.put(
        `${baseUrl+endpoint}/${id}?status=${status}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        let errMsg = handleErrors(error.response, navigate);
        throw errMsg;
    } else if (error.request) {
        const errorMessage = handleErrors(0, navigate);
        throw errorMessage;
    } else {
        throw error.message;
    }
    }
  };

   export const putUser = async (url, body, navigate) => {
    try {
        const response = await axios.put(
            `${baseUrl}${url}`,
            body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getAuthToken()}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        if (error.response) {
            let errMsg = handleErrors(error.response, navigate);
            throw errMsg;
        } else if (error.request) {
            const errorMessage = handleErrors(0, navigate);
            throw errorMessage;
        } else {
            throw error.message;
        }
    }
  };



  export const postRequest = async (url, payload, navigate) => {
    try {
      const response = await axios.post(baseUrl+url, payload, {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        let errMsg = handleErrors(error.response, navigate);
        throw errMsg;
    } else if (error.request) {
        const errorMessage = handleErrors(0, navigate);
        throw errorMessage;
    } else {
        throw error.message;
    }
    }
  };

  export const  csvParse = async (url, navigate) =>{
    try{
      const response = await axios.get(`${baseUrl+url}`, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
        responseType: 'text',
      });
      return response;
    } catch(error){
      if (error.response) {
        let errMsg = handleErrors(error.response, navigate);
        throw errMsg;
    } else if (error.request) {
        const errorMessage = handleErrors(0, navigate);
        throw errorMessage;
    } else {
        throw error.message;
    }
    }
  }

  export const getVast = async (url , navigate) => {
  try{
    const response = await axios.get(`${baseUrl+url}`, {
      headers: {Authorization: `Bearer ${getAuthToken()}`},
      responseType: 'text',
    });
  }catch(error){
    if (error.response) {
      let errMsg = handleErrors(error.response, navigate);
      throw errMsg;
  } else if (error.request) {
      const errorMessage = handleErrors(0, navigate);
      throw errorMessage;
  } else {
      throw error.message;
  }
  }
 }