
const handleErrors = (response, navigate) => {
    let message = '';
    let status = response.status;
    switch (status) {
        case 400:
            message = 'The server cannot process the request.';
            break;
        case 401:
            message = 'Invalid credentials.';
            localStorage.removeItem('ae-internal-ui');
            localStorage.removeItem('user');
            navigate('/login');
            break;
        case 403:
            message = 'Access denied.';
            break;
        case 404:
            message = 'Page not found.';
            break;
        case 409:
            message = 'Conflict - There was a conflict with the current state of the server.';
            break;
        case 429:
            message = 'Too Many Requests - You have exceeded the rate limit.';
            break;
        case 500:
            message = response?.data?.detail ?? 'Internal Server Error - Something went wrong on the server.';
            break;
        case 503:
            message = 'Service Unavailable - The server is currently unavailable.';
            break;
        default:
            if (status >= 400 && status < 500) {
                message = 'Client Error - There was an issue with your request.';
            } else if (status >= 500 && status < 600) {
                message = 'Server Error - Something went wrong on the server.';
            } else {
                message = 'An error occurred. Please try again.';
            }
    }
    return message;
};

export default handleErrors;