import Login from "../../pages/Login/login";
import Registration from "../../pages/Login/UserRegistration";

export const auth_routes = [
    // {
    //     path: "/",
    //     element: <Login />,
    // },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/register",
        element: <Registration/>
    }
    // {
    //     path: "/reset-password",
    //     ele: <>,
    // }
]