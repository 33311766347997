import React from "react";
import { Navigate } from "react-router-dom";
import { getLoggedInUser } from "../../auth/authUtil";

const ProtectedRoute = ({ children,userRoles }) => {
   // const token = localStorage.getItem('ae-internal-ui');
    let currentUser = getLoggedInUser();
    if (currentUser) {
        if (userRoles) {
            let isAuth = currentUser?.authorities?.some(item => userRoles.includes(item))
            return isAuth ? children : <Navigate to="/login" />
        } else {
            return children;
        }
    } else {
        return <Navigate to="/login" />
    }

    // if (!token) {
    //     return <Navigate to="/login" />;
    // }
    // return children;
};

export default ProtectedRoute;
